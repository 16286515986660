





























































































import VueAsyncComputed from 'vue-async-computed';
import {Component, Vue} from 'vue-property-decorator';
import GenericFilterComponent from '@/components/search/GenericFilterComponent.vue';
import GenericFilterResults from '@/components/search/GenericFilterResults.vue';
import {ArkStatsBackendClient, ResourceListDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import {ServerDto} from '@/logic/ArkServerManagerClient';
import ServerMessageDto from '@/logic/ark-stats-backend/dto/servermessage/ServerMessageDto';
import {AuthBackendAPI} from '@/logic/authentication/AuthBackendAPI';

Vue.use(VueAsyncComputed);

@Component({
    components: { GenericFilterComponent, GenericFilterResults }
})
export default class ServerMessages extends Vue {

    private dateFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} as const;
    private initialized = false;
    private serverMessages: ServerMessageDto[] = [];
    private servers: ServerDto[] = [];
    private editing: ServerMessageDto|null = null;
    private editingFrom = "";
    private editingTo = "";
    private showEditDialog = false;

    async created(): Promise<void> {
        await this.loadServerMessages();
    }

    getAuthenticationLink(): string {
        return "https://auth.domination-gaming.com/oauth/discord?redirect_uri=" + encodeURIComponent(window.location.href);
    }

    getDatePart(date: Date): string {
        if(!date) {
            return '';
        }
        return date.toUTCString().split('T')[0];
    }

    messageIsValid(): boolean {
        return !!this.editing && !!this.editing.message && this.dateIsValid(this.editingFrom) && this.dateIsValid(this.editingTo);
    }

    dateIsValid(value: string): boolean {
        if(!value) {
            return true;
        }
        try {
            return value === new Date(value).toISOString();
        } catch(e) {
            return false;
        }
    }

    editMessage(serverMessage: ServerMessageDto): void {
        this.editing = {
            id: serverMessage.id,
            from: serverMessage.from,
            to: serverMessage.to,
            message: serverMessage.message,
            disabled: serverMessage.disabled,
            serverIds: serverMessage.serverIds
        };
        this.editingFrom = this.editing.from ? this.editing.from.toISOString() : "";
        this.editingTo = this.editing.to ? this.editing.to.toISOString() : "";
        this.showEditDialog = true;
    }

    newMessage(): void {
        this.editMessage(new ServerMessageDto());
    }

    hideEditDialog(): void {
        this.showEditDialog = false;
        this.editing = null;
    }

    async save(message: ServerMessageDto): Promise<void> {
        message.from = this.editingFrom ? new Date(this.editingFrom) : null;
        message.to = this.editingTo ? new Date(this.editingTo) : null;
        if(!message.id) {
            await ArkStatsBackendClient.postServerMessage(message);
        } else {
            await ArkStatsBackendClient.putServerMessage(message.id, message);
        }
        await this.loadServerMessages();
        this.hideEditDialog();
    }

    async deleteMessage(message: ServerMessageDto): Promise<void> {
        if(!message.id) {
            return;
        }
        await ArkStatsBackendClient.deleteServerMessage(message.id);
        await this.loadServerMessages();
        this.hideEditDialog();
    }

    async loadServerMessages(): Promise<void> {
        this.serverMessages = (await ArkStatsBackendClient.getServerMessages({})).data;
        this.servers = (await ArkStatsBackendClient.getGenericObject<ResourceListDto<ServerDto>>("server", { fields: ['id', 'name' ], orderBy: 'id'})).data;
        this.initialized = true;
    }

    isAdmin(): boolean {
        return AuthBackendAPI.isAdmin(this.$store.state.currentUserState);
    }

    public localDate(timestamp: string | Date): string {
        return new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(new Date(timestamp));
    }
}
