






import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import GenericFilter, {AutoComplete} from '@/components/search/GenericFilter';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, ResourceListDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class AutoCompleteComponent extends Vue {

    @Prop({ required: true })
    filter!: GenericFilter;

    @AsyncComputedProp()
    public async items(): Promise<any[] | null> {
        if(!this.filter?.autocomplete) {
            console.error("Auto complete not defined for filter", this.filter);
            return [];
        }
        const autocomplete: AutoComplete = this.filter.autocomplete;

        if(autocomplete.labelField === autocomplete.idField) {
            return (await ArkStatsBackendClient.getGenericValues(
                autocomplete.resource,
                autocomplete.idField
            )).map((item: any) => { return {
                text: item,
                value: item
            }});
        }

        const results: ResourceListDto<any> = (await ArkStatsBackendClient.getGenericObject(
            autocomplete.resource, {
                fields: [autocomplete.idField, autocomplete.labelField]
            }
        ));

        return results.data.map((result: any) => { return {
            text: this.resolve(result, autocomplete.labelField),
            value: this.resolve(result, autocomplete.idField)
        }});

    }

    private resolve(obj: any, jsonPath: string) {
        let result = obj;
        jsonPath.split('.').forEach(pathPart => {
            result = result[pathPart];
        });
        return result;
    }

}
