






import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import GenericFilter, {AutoComplete} from '@/components/search/GenericFilter';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, ResourceListDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class SelectComponent extends Vue {

    @Prop({ required: true })
    filter!: GenericFilter;

    @AsyncComputedProp()
    public async items(): Promise<any[] | null> {
        if(!this.filter?.options) {
            console.error("Select does not define options for filter", this.filter);
            return [];
        }

        return Object.entries(this.filter.options).map(([key, value]) => {
            return {
                text: value,
                value: key
            }
        });
    }

    private resolve(obj: any, jsonPath: string) {
        let result = obj;
        jsonPath.split('.').forEach(pathPart => {
            result = result[pathPart];
        });
        return result;
    }

}
