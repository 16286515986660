






import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import GenericFilter, {AutoComplete} from '@/components/search/GenericFilter';
import AutoCompleteComponent from '@/components/search/AutoCompleteComponent.vue';
import SelectComponent from '@/components/search/SelectComponent.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: {SelectComponent, AutoCompleteComponent}
})
export default class GenericFilterComponent extends Vue {

    @Prop({ required: true })
    filter!: GenericFilter;

}
