










import VueAsyncComputed from 'vue-async-computed';
import {Component, Prop, Vue} from 'vue-property-decorator';
import GenericFilterComponent from '@/components/search/GenericFilterComponent.vue';
import GenericFilter from '@/components/search/GenericFilter';
import SearchConfiguration, {GenericResultColumn} from '@/components/search/SearchConfiguration';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, ItemDto, ResourceListDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';

Vue.use(VueAsyncComputed);

@Component({
    components: { }
})
export default class GenericFilterResults extends Vue {
    @Prop( { required: true} )
    searchConfiguration!: SearchConfiguration;

    @AsyncComputedProp()
    public async searchResults(): Promise<any[]> {
        const results: ResourceListDto<any> = (await ArkStatsBackendClient.getGenericObject(this.searchConfiguration.resource, {
            filter: this.filter(),
            fields: this.fields()
        }));
        const searchResults = results.data.map(result => {
            return Object.fromEntries(Object.entries(this.searchConfiguration.columns).map(entry => {
                return [entry[0], entry[1].displayValue
                    ? entry[1].displayValue(this.resolve(result, entry[1].field))
                    : this.resolve(result, entry[1].field)];
            }));
        });
        return searchResults;
    }



    private resolve(obj: any, jsonPath: string) {
        let result = obj;
        jsonPath.split('.').forEach(pathPart => {
            result = result[pathPart];
        });
        return result;
    }

    public filter(): string {
        let filters = [];
        for (const [key, value] of Object.entries(this.searchConfiguration.filters)) {
            if(value.selected && value.selected.length) {
                filters.push(`${value.field}=in=("${value.selected.join('","')}")`);
            }
        }
        return filters.join(';');
    }

    public fields(): string[] {
        return Object.values(this.searchConfiguration.columns)
            .map(column => column.field);
    }

    private headers() {
        return Object.entries(this.searchConfiguration.columns).map(entry => {
            return {
                text: entry[1].label,
                value: entry[0]
            };
        });
    }

}
